// File: /src/components/AvantiIndietroStepButtons/AvantiIndietroStepButtons.tsx
// Created Date: Tuesday September 3rd 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday September 3rd 2024 3:14:20 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import React from "react";
import { StyledAvantiIndietroStepButtons } from "./styles";
import Button from "../Button";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

const AvantiIndietroStepButtons = (props: {
  onNext: () => void;
  onPrevious: () => void;
  nextDisabled?: boolean;
  loading?: boolean;
}) => {
  return (
    <StyledAvantiIndietroStepButtons>
      <Button
        loading={props.loading}
        className={
          ["button-instance"] + (props.nextDisabled ? "button-disabled" : "")
        }
        property1="primary"
        text={props.loading ? "Attendi..." : ("Avanti" as any)}
        onClick={props.onNext}
        rightDecorator={(<ArrowRight weight="bold" size={18} />) as any}
      >
        <></>
      </Button>
      <Button
        loading={props.loading}
        className="button-instance"
        property1="secondary"
        text={"Indietro" as any}
        onClick={props.onPrevious}
        leftDecorator={(<ArrowLeft weight="bold" size={18} />) as any}
      >
        <></>
      </Button>
    </StyledAvantiIndietroStepButtons>
  );
};

export default AvantiIndietroStepButtons;
