import styled from "styled-components";

export const StyledDropdown = styled.div`
  cursor: pointer;

  select {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 50;
    position: absolute;
  }

  .select {
    height: 60px;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    font-size: 14px;
    color: #000;
    outline: none;
    cursor: pointer;
    font-size: inherit;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;

    p {
      position: absolute;
      left: 28px;
      top: 50%;
      margin: 0;
      transform: translateY(-50%);
      font-size: 18px;
      font-weight: 600;
    }

    .arr-wrapper {
      position: absolute;
      right: 28px;
      top: 50%;
      margin: 0;

      .arr {
        display: block;
        width: 12px;
        height: 12px;
        border-left: 2.5px solid #878787;
        border-bottom: 2.5px solid #878787;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -75%) rotate(-45deg);
      }
    }

    .drawer-act {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      opacity: 0;
      cursor: pointer;
    }

    .drawer {
      position: absolute;
      top: 62px;
      border: 1px solid #c4c4c4;
      left: 0;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      z-index: 50;
      display: none;

      &.open {
        display: block;
      }

      ul {
        list-style: none;
        padding-inline: 10px;
        padding-block: 10px;
        margin: 0;
        display: grid;
        grid-gap: 4px;

        li {
          padding: 8px 16px;
          cursor: pointer;
          border-radius: 8px;

          &:hover {
            background-color: #f5f5f590;
          }

          &.selected {
            background-color: #d7d7d7;
          }
        }
      }
    }
  }
`;
