import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOnboardingActions } from "../../../store/slices/onboarding";
import { doc, getFirestore } from "firebase/firestore";
import { setDoc } from "firebase/firestore";
import { app } from "../../../lib/firebase/config";
import { toast } from "sonner";
import {
  adaptNewExperiencesToOldFormat,
  saveToFirebase,
} from "../../../utils/onboarding";

export const useStepNavigation = (
  stepIndex: number,
  steps: any[],
  validateFields: () => boolean,
  validateMandatoryFields: (
    composable?: boolean,
    blockIndex?: number
  ) => boolean,
  formData: any,
  setComposableBlocks: (blocks: any[]) => void,
  getComposableBlocks: (si?: number) => any[]
) => {
  const navigate = useNavigate();
  const {
    reduceStepIndex,
    increaseStepIndex,
    setErrorMessage,
    setErrorFieldKey,
    initializeEmptyStep,
    setLoading,
  } = useOnboardingActions();

  const [searchParams] = useSearchParams();

  const processStep = useCallback(
    async (stepData: Record<string, any>) => {
      try {
        setLoading(true);
        setErrorMessage(null);
        setErrorFieldKey(null);
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("step", (stepIndex + 1).toString());
        let data = {};
        // Filter out undefined values from the data object
        data = Object.fromEntries(
          Object.entries(stepData).filter(([_, value]) => value !== undefined)
        );
        const cleanedFormData = Object.fromEntries(
          Object.entries(formData).filter(([_, value]) => value !== undefined)
        );
        if ("codice_fiscale" in stepData) {
          data = {
            ...stepData,
            user_type: "lavoratore",
            ...cleanedFormData,
            creation_time: new Date().toISOString(),
          };
        }
        if ("experiences" in stepData) {
          const adaptedExperiences = adaptNewExperiencesToOldFormat(
            stepData.experiences
          );
          data = { ...data, experiences: adaptedExperiences };
        }
        if (
          formData.codice_fiscale &&
          formData.codice_fiscale !== "undefined"
        ) {
          newSearchParams.set("codice_fiscale", formData.codice_fiscale);
          await saveToFirebase(formData.codice_fiscale, data);
        }
        increaseStepIndex();
        navigate(`/onboarding-lavoratori?${newSearchParams.toString()}`);
      } catch (err) {
        toast.error("Errore nel salvataggio dei dati");
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [
      setErrorMessage,
      setErrorFieldKey,
      increaseStepIndex,
      formData,
      navigate,
      searchParams,
      stepIndex,
      setLoading,
    ]
  );

  const handleClickNextButton = useCallback(async () => {
    if (!validateFields()) return;

    if (steps[stepIndex]?.mandatory && steps[stepIndex]?.fields) {
      if (steps[stepIndex]?.compositeOptions) {
        const composableLength = formData[steps[stepIndex].key]?.length || 0;
        for (let blockIndex = 0; blockIndex < composableLength; blockIndex++) {
          if (!validateMandatoryFields(true, blockIndex)) return;
        }
      } else {
        if (!validateMandatoryFields()) return;
      }
    }

    if (stepIndex === steps.length - 1) {
      const stepFieldValues: Record<string, any> = {};
      for (const field of steps[stepIndex]?.fields || []) {
        const key = field.key.includes(".") ? steps[stepIndex].key : field.key;
        stepFieldValues[key] = formData[key];
      }
      await processStep(stepFieldValues);
      navigate(`/success`);
    } else {
      const stepFieldValues: Record<string, any> = {};
      for (const field of steps[stepIndex]?.fields || []) {
        const key = field.key.includes(".") ? steps[stepIndex].key : field.key;
        stepFieldValues[key] = formData[key];
      }
      initializeEmptyStep(steps[stepIndex + 1]);

      await processStep(stepFieldValues);
    }
  }, [
    stepIndex,
    steps,
    validateFields,
    validateMandatoryFields,
    formData,
    navigate,
    processStep,
    initializeEmptyStep,
  ]);

  const handleClickPreviousButton = () => {
    if (stepIndex === 0) {
      navigate("/");
    } else {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("step", (stepIndex - 1).toString());
      navigate(`/onboarding-lavoratori?${newSearchParams.toString()}`);
      setErrorMessage(null);
      setErrorFieldKey(null);
      reduceStepIndex();
    }
  };

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === "Enter") handleClickNextButton();
    };
    window.addEventListener("keydown", keyDownListener);
    return () => window.removeEventListener("keydown", keyDownListener);
  }, [handleClickNextButton]);

  return {
    handleClickNextButton,
    handleClickPreviousButton,
  };
};
