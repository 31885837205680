export const COUNTRIES = [
  "Afghanistan",
  "Isole Åland",
  "Albania",
  "Algeria",
  "Samoa Americane",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antartide",
  "Antigua e Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaigian",
  "Bahamas",
  "Bahrein",
  "Bangladesh",
  "Barbados",
  "Bielorussia",
  "Belgio",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius e Saba",
  "Bosnia ed Erzegovina",
  "Botswana",
  "Isola Bouvet",
  "Brasile",
  "Territorio britannico dell'Oceano Indiano",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Capo Verde",
  "Cambogia",
  "Camerun",
  "Canada",
  "Isole Cayman",
  "Repubblica Centrafricana",
  "Ciad",
  "Cile",
  "Cina",
  "Isola di Natale",
  "Isole Cocos",
  "Colombia",
  "Comore",
  "Congo (Repubblica del)",
  "Congo (Repubblica Democratica del)",
  "Isole Cook",
  "Costa Rica",
  "Costa d'Avorio",
  "Croazia",
  "Cuba",
  "Curaçao",
  "Cipro",
  "Repubblica Ceca",
  "Danimarca",
  "Gibuti",
  "Dominica",
  "Repubblica Dominicana",
  "Ecuador",
  "Egitto",
  "El Salvador",
  "Guinea Equatoriale",
  "Eritrea",
  "Estonia",
  "Etiopia",
  "Isole Falkland",
  "Isole Faroe",
  "Figi",
  "Finlandia",
  "Francia",
  "Guyana Francese",
  "Polinesia Francese",
  "Territori Francesi del Sud",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germania",
  "Ghana",
  "Gibilterra",
  "Grecia",
  "Groenlandia",
  "Grenada",
  "Guadalupa",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Isole Heard e McDonald",
  "Città del Vaticano",
  "Honduras",
  "Hong Kong",
  "Ungheria",
  "Islanda",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Irlanda",
  "Isola di Man",
  "Israele",
  "Italia",
  "Giamaica",
  "Giappone",
  "Jersey",
  "Giordania",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Corea del Nord",
  "Corea del Sud",
  "Kuwait",
  "Kirghizistan",
  "Laos",
  "Lettonia",
  "Libano",
  "Lesotho",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Lussemburgo",
  "Macao",
  "Macedonia del Nord",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldive",
  "Mali",
  "Malta",
  "Isole Marshall",
  "Martinica",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Messico",
  "Micronesia",
  "Moldavia",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marocco",
  "Mozambico",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Paesi Bassi",
  "Nuova Caledonia",
  "Nuova Zelanda",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Isola Norfolk",
  "Isole Marianne Settentrionali",
  "Norvegia",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestina",
  "Panama",
  "Papua Nuova Guinea",
  "Paraguay",
  "Perù",
  "Filippine",
  "Pitcairn",
  "Polonia",
  "Portogallo",
  "Porto Rico",
  "Qatar",
  "Riunione",
  "Romania",
  "Russia",
  "Ruanda",
  "Saint-Barthélemy",
  "Sant'Elena, Ascensione e Tristan da Cunha",
  "Saint Kitts e Nevis",
  "Santa Lucia",
  "Saint Martin",
  "Saint Pierre e Miquelon",
  "Saint Vincent e Grenadine",
  "Samoa",
  "San Marino",
  "São Tomé e Príncipe",
  "Arabia Saudita",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovacchia",
  "Slovenia",
  "Isole Salomone",
  "Somalia",
  "Sudafrica",
  "Georgia del Sud e Sandwich Australi",
  "Sud Sudan",
  "Spagna",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard e Jan Mayen",
  "Swaziland",
  "Svezia",
  "Svizzera",
  "Siria",
  "Taiwan",
  "Tagikistan",
  "Tanzania",
  "Thailandia",
  "Timor Est",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad e Tobago",
  "Tunisia",
  "Turchia",
  "Turkmenistan",
  "Isole Turks e Caicos",
  "Tuvalu",
  "Uganda",
  "Ucraina",
  "Emirati Arabi Uniti",
  "Regno Unito",
  "Stati Uniti d'America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Isole Vergini Britanniche",
  "Isole Vergini Americane",
  "Wallis e Futuna",
  "Sahara Occidentale",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const COUNTRIES_MAP = {
  Afghanistan: "Afghanistan",
  "Åland Islands": "Isole Åland",
  Albania: "Albania",
  Algeria: "Algeria",
  "American Samoa": "Samoa Americane",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  Antarctica: "Antartide",
  "Antigua and Barbuda": "Antigua e Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaigian",
  Bahamas: "Bahamas",
  Bahrain: "Bahrein",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Bielorussia",
  Belgium: "Belgio",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  "Bolivia (Plurinational State of)": "Bolivia",
  "Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius e Saba",
  "Bosnia and Herzegovina": "Bosnia ed Erzegovina",
  Botswana: "Botswana",
  "Bouvet Island": "Isola Bouvet",
  Brazil: "Brasile",
  "British Indian Ocean Territory": "Territorio britannico dell'Oceano Indiano",
  "Brunei Darussalam": "Brunei",
  Bulgaria: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  "Cabo Verde": "Capo Verde",
  Cambodia: "Cambogia",
  Cameroon: "Camerun",
  Canada: "Canada",
  "Cayman Islands": "Isole Cayman",
  "Central African Republic": "Repubblica Centrafricana",
  Chad: "Ciad",
  Chile: "Cile",
  China: "Cina",
  "Christmas Island": "Isola di Natale",
  "Cocos (Keeling) Islands": "Isole Cocos",
  Colombia: "Colombia",
  Comoros: "Comore",
  "Congo (Republic of the)": "Congo (Repubblica del)",
  "Congo (Democratic Republic of the)": "Congo (Repubblica Democratica del)",
  "Cook Islands": "Isole Cook",
  "Costa Rica": "Costa Rica",
  "Côte d'Ivoire": "Costa d'Avorio",
  Croatia: "Croazia",
  Cuba: "Cuba",
  Curaçao: "Curaçao",
  Cyprus: "Cipro",
  "Czech Republic": "Repubblica Ceca",
  Denmark: "Danimarca",
  Djibouti: "Gibuti",
  Dominica: "Dominica",
  "Dominican Republic": "Repubblica Dominicana",
  Ecuador: "Ecuador",
  Egypt: "Egitto",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Guinea Equatoriale",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Ethiopia: "Etiopia",
  "Falkland Islands (Malvinas)": "Isole Falkland",
  "Faroe Islands": "Isole Faroe",
  Fiji: "Figi",
  Finland: "Finlandia",
  France: "Francia",
  "French Guiana": "Guyana Francese",
  "French Polynesia": "Polinesia Francese",
  "French Southern Territories": "Territori Francesi del Sud",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germania",
  Ghana: "Ghana",
  Gibraltar: "Gibilterra",
  Greece: "Grecia",
  Greenland: "Groenlandia",
  Grenada: "Grenada",
  Guadeloupe: "Guadalupa",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Heard Island and McDonald Islands": "Isole Heard e McDonald",
  "Vatican City State": "Città del Vaticano",
  Honduras: "Honduras",
  "Hong Kong": "Hong Kong",
  Hungary: "Ungheria",
  Iceland: "Islanda",
  India: "India",
  Indonesia: "Indonesia",
  Iran: "Iran",
  Iraq: "Iraq",
  Ireland: "Irlanda",
  "Isle of Man": "Isola di Man",
  Israel: "Israele",
  Italy: "Italia",
  Jamaica: "Giamaica",
  Japan: "Giappone",
  Jersey: "Jersey",
  Jordan: "Giordania",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  "Korea (Democratic People's Republic of)": "Corea del Nord",
  "Korea (Republic of)": "Corea del Sud",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kirghizistan",
  "Lao People's Democratic Republic": "Laos",
  Latvia: "Lettonia",
  Lebanon: "Libano",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libia",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lituania",
  Luxembourg: "Lussemburgo",
  Macao: "Macao",
  "Macedonia (the former Yugoslav Republic of)": "Macedonia del Nord",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldive",
  Mali: "Mali",
  Malta: "Malta",
  "Marshall Islands": "Isole Marshall",
  Martinique: "Martinica",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mayotte: "Mayotte",
  Mexico: "Messico",
  "Micronesia (Federated States of)": "Micronesia",
  "Moldova (Republic of)": "Moldavia",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Morocco: "Marocco",
  Mozambique: "Mozambico",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Paesi Bassi",
  "New Caledonia": "Nuova Caledonia",
  "New Zealand": "Nuova Zelanda",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Norfolk Island": "Isola Norfolk",
  "Northern Mariana Islands": "Isole Marianne Settentrionali",
  Norway: "Norvegia",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  "Palestine, State of": "Palestina",
  Panama: "Panama",
  "Papua New Guinea": "Papua Nuova Guinea",
  Paraguay: "Paraguay",
  Peru: "Perù",
  Philippines: "Filippine",
  Pitcairn: "Pitcairn",
  Poland: "Polonia",
  Portugal: "Portogallo",
  "Puerto Rico": "Porto Rico",
  Qatar: "Qatar",
  Réunion: "Riunione",
  Romania: "Romania",
  "Russian Federation": "Russia",
  Rwanda: "Ruanda",
  "Saint Barthélemy": "Saint-Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha":
    "Sant'Elena, Ascensione e Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts e Nevis",
  "Saint Lucia": "Santa Lucia",
  "Saint Martin (French part)": "Saint Martin",
  "Saint Pierre and Miquelon": "Saint Pierre e Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent e Grenadine",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "São Tomé e Príncipe",
  "Saudi Arabia": "Arabia Saudita",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapore",
  "Sint Maarten (Dutch part)": "Sint Maarten",
  Slovakia: "Slovacchia",
  Slovenia: "Slovenia",
  "Solomon Islands": "Isole Salomone",
  Somalia: "Somalia",
  "South Africa": "Sudafrica",
  "South Georgia and the South Sandwich Islands":
    "Georgia del Sud e Sandwich Australi",
  "South Sudan": "Sud Sudan",
  Spain: "Spagna",
  "Sri Lanka": "Sri Lanka",
  Sudan: "Sudan",
  Suriname: "Suriname",
  "Svalbard and Jan Mayen": "Svalbard e Jan Mayen",
  Swaziland: "Swaziland",
  Sweden: "Svezia",
  Switzerland: "Svizzera",
  "Syrian Arab Republic": "Siria",
  "Taiwan, Province of China": "Taiwan",
  Tajikistan: "Tagikistan",
  "Tanzania, United Republic of": "Tanzania",
  Thailand: "Thailandia",
  "Timor-Leste": "Timor Est",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad e Tobago",
  Tunisia: "Tunisia",
  Turkey: "Turchia",
  Turkmenistan: "Turkmenistan",
  "Turks and Caicos Islands": "Isole Turks e Caicos",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ucraina",
  "United Arab Emirates": "Emirati Arabi Uniti",
  "United Kingdom of Great Britain and Northern Ireland": "Regno Unito",
  "United States Minor Outlying Islands":
    "Isole minori esterne degli Stati Uniti",
  "United States of America": "Stati Uniti d'America",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  "Venezuela (Bolivarian Republic of)": "Venezuela",
  Vietnam: "Vietnam",
  "Virgin Islands (British)": "Isole Vergini Britanniche",
  "Virgin Islands (U.S.)": "Isole Vergini Americane",
  "Wallis and Futuna": "Wallis e Futuna",
  "Western Sahara": "Sahara Occidentale",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
};

export const COUNTRIES_MAP_2 = {
  Afghan: "Afghanistan",
  "Åland Island": "Isole Åland",
  Albanian: "Albania",
  Algerian: "Algeria",
  "American Samoan": "Samoa Americane",
  Andorran: "Andorra",
  Angolan: "Angola",
  Anguillan: "Anguilla",
  Antarctic: "Antartide",
  "Antiguan or Barbudan": "Antigua e Barbuda",
  Argentine: "Argentina",
  Armenian: "Armenia",
  Aruban: "Aruba",
  Australian: "Australia",
  Austrian: "Austria",
  "Azerbaijani, Azeri": "Azerbaigian",
  Bahamian: "Bahamas",
  Bahraini: "Bahrein",
  Bangladeshi: "Bangladesh",
  Barbadian: "Barbados",
  Belarusian: "Bielorussia",
  Belgian: "Belgio",
  Belizean: "Belize",
  "Beninese, Beninois": "Benin",
  "Bermudian, Bermudan": "Bermuda",
  Bhutanese: "Bhutan",
  Bolivian: "Bolivia",
  Bonaire: "Bonaire, Sint Eustatius e Saba",
  "Bosnian or Herzegovinian": "Bosnia ed Erzegovina",
  "Motswana, Botswanan": "Botswana",
  "Bouvet Island": "Isola Bouvet",
  Brazilian: "Brasile",
  BIOT: "Territorio britannico dell'Oceano Indiano",
  Bruneian: "Brunei",
  Bulgarian: "Bulgaria",
  Burkinabé: "Burkina Faso",
  Burundian: "Burundi",
  "Cabo Verdean": "Capo Verde",
  Cambodian: "Cambogia",
  Cameroonian: "Camerun",
  Canadian: "Canada",
  Caymanian: "Isole Cayman",
  "Central African": "Repubblica Centrafricana",
  Chadian: "Ciad",
  Chilean: "Cile",
  Chinese: "Cina",
  "Christmas Island": "Isola di Natale",
  "Cocos Island": "Isole Cocos",
  Colombian: "Colombia",
  "Comoran, Comorian": "Comore",
  Congolese: "Congo (Repubblica del)",
  "Cook Island": "Isole Cook",
  "Costa Rican": "Costa Rica",
  Ivorian: "Costa d'Avorio",
  Croatian: "Croazia",
  Cuban: "Cuba",
  Curaçaoan: "Curaçao",
  Cypriot: "Cipro",
  Czech: "Repubblica Ceca",
  Danish: "Danimarca",
  Djiboutian: "Gibuti",
  Dominican: "Repubblica Dominicana",
  Ecuadorian: "Ecuador",
  Egyptian: "Egitto",
  Salvadoran: "El Salvador",
  "Equatorial Guinean, Equatoguinean": "Guinea Equatoriale",
  Eritrean: "Eritrea",
  Estonian: "Estonia",
  Ethiopian: "Etiopia",
  "Falkland Island": "Isole Falkland",
  Faroese: "Isole Faroe",
  Fijian: "Figi",
  Finnish: "Finlandia",
  French: "Francia",
  "French Guianese": "Guyana Francese",
  "French Polynesian": "Polinesia Francese",
  "French Southern Territories": "Territori Francesi del Sud",
  Gabonese: "Gabon",
  Gambian: "Gambia",
  Georgian: "Georgia",
  German: "Germania",
  Ghanaian: "Ghana",
  Gibraltar: "Gibilterra",
  "Greek, Hellenic": "Grecia",
  Greenlandic: "Groenlandia",
  Grenadian: "Grenada",
  Guadeloupe: "Guadalupa",
  "Guamanian, Guambat": "Guam",
  Guatemalan: "Guatemala",
  "Channel Island": "Guernsey",
  Guinean: "Guinea",
  "Bissau-Guinean": "Guinea-Bissau",
  Guyanese: "Guyana",
  Haitian: "Haiti",
  "Heard Island or McDonald Islands": "Isole Heard e McDonald",
  Vatican: "Città del Vaticano",
  Honduran: "Honduras",
  "Hong Kong, Hong Kongese": "Hong Kong",
  "Hungarian, Magyar": "Ungheria",
  Icelandic: "Islanda",
  Indian: "India",
  Indonesian: "Indonesia",
  "Iranian, Persian": "Iran",
  Iraqi: "Iraq",
  Irish: "Irlanda",
  Manx: "Isola di Man",
  Israeli: "Israele",
  Italian: "Italia",
  Jamaican: "Giamaica",
  Japanese: "Giappone",
  Jordanian: "Giordania",
  "Kazakhstani, Kazakh": "Kazakhstan",
  Kenyan: "Kenya",
  "I-Kiribati": "Kiribati",
  "North Korean": "Corea del Nord",
  "South Korean": "Corea del Sud",
  Kuwaiti: "Kuwait",
  "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz": "Kirghizistan",
  "Lao, Laotian": "Laos",
  Latvian: "Lettonia",
  Lebanese: "Libano",
  Basotho: "Lesotho",
  Liberian: "Liberia",
  Libyan: "Libia",
  Liechtenstein: "Liechtenstein",
  Lithuanian: "Lituania",
  "Luxembourg, Luxembourgish": "Lussemburgo",
  "Macanese, Chinese": "Macao",
  Macedonian: "Macedonia del Nord",
  Malagasy: "Madagascar",
  Malawian: "Malawi",
  Malaysian: "Malaysia",
  Maldivian: "Maldive",
  "Malian, Malinese": "Mali",
  Maltese: "Malta",
  Marshallese: "Isole Marshall",
  "Martiniquais, Martinican": "Martinica",
  Mauritanian: "Mauritania",
  Mauritian: "Mauritius",
  Mahoran: "Mayotte",
  Mexican: "Messico",
  Micronesian: "Micronesia",
  Moldovan: "Moldavia",
  "Monégasque, Monacan": "Monaco",
  Mongolian: "Mongolia",
  Montenegrin: "Montenegro",
  Montserratian: "Montserrat",
  Moroccan: "Marocco",
  Mozambican: "Mozambico",
  Burmese: "Myanmar",
  Namibian: "Namibia",
  Nauruan: "Nauru",
  "Nepali, Nepalese": "Nepal",
  "Dutch, Netherlandic": "Paesi Bassi",
  "New Caledonian": "Nuova Caledonia",
  "New Zealand, NZ": "Nuova Zelanda",
  Nicaraguan: "Nicaragua",
  Nigerien: "Niger",
  Nigerian: "Nigeria",
  Niuean: "Niue",
  "Norfolk Island": "Isola Norfolk",
  "Northern Marianan": "Isole Marianne Settentrionali",
  Norwegian: "Norvegia",
  Omani: "Oman",
  Pakistani: "Pakistan",
  Palauan: "Palau",
  Palestinian: "Palestina",
  Panamanian: "Panama",
  "Papua New Guinean, Papuan": "Papua Nuova Guinea",
  Paraguayan: "Paraguay",
  Peruvian: "Perù",
  "Philippine, Filipino": "Filippine",
  "Pitcairn Island": "Pitcairn",
  Polish: "Polonia",
  Portuguese: "Portogallo",
  "Puerto Rican": "Porto Rico",
  Qatari: "Qatar",
  "Réunionese, Réunionnais": "Riunione",
  Romanian: "Romania",
  Russian: "Russia",
  Rwandan: "Ruanda",
  Barthélemois: "Saint-Barthélemy",
  "Saint Helenian": "Sant'Elena, Ascensione e Tristan da Cunha",
  "Kittitian or Nevisian": "Saint Kitts e Nevis",
  "Saint Lucian": "Santa Lucia",
  "Saint-Martinoise": "Saint Martin",
  "Saint-Pierrais or Miquelonnais": "Saint Pierre e Miquelon",
  "Saint Vincentian, Vincentian": "Saint Vincent e Grenadine",
  Samoan: "Samoa",
  Sammarinese: "San Marino",
  "São Toméan": "São Tomé e Príncipe",
  "Saudi, Saudi Arabian": "Arabia Saudita",
  Senegalese: "Senegal",
  Serbian: "Serbia",
  Seychellois: "Seychelles",
  "Sierra Leonean": "Sierra Leone",
  Singaporean: "Singapore",
  "Sint Maarten": "Sint Maarten",
  Slovak: "Slovacchia",
  "Slovenian, Slovene": "Slovenia",
  "Solomon Island": "Isole Salomone",
  "Somali, Somalian": "Somalia",
  "South African": "Sudafrica",
  "South Georgia or South Sandwich Islands":
    "Georgia del Sud e Sandwich Australi",
  "South Sudanese": "Sud Sudan",
  Spanish: "Spagna",
  "Sri Lankan": "Sri Lanka",
  Sudanese: "Sudan",
  Surinamese: "Suriname",
  Svalbard: "Svalbard e Jan Mayen",
  Swazi: "Swaziland",
  Swedish: "Svezia",
  Swiss: "Svizzera",
  Syrian: "Siria",
  "Chinese, Taiwanese": "Taiwan",
  Tajikistani: "Tagikistan",
  Tanzanian: "Tanzania",
  Thai: "Thailandia",
  Timorese: "Timor Est",
  Togolese: "Togo",
  Tokelauan: "Tokelau",
  Tongan: "Tonga",
  "Trinidadian or Tobagonian": "Trinidad e Tobago",
  Tunisian: "Tunisia",
  Turkish: "Turchia",
  Turkmen: "Turkmenistan",
  "Turks and Caicos Island": "Isole Turks e Caicos",
  Tuvaluan: "Tuvalu",
  Ugandan: "Uganda",
  Ukrainian: "Ucraina",
  "Emirati, Emirian, Emiri": "Emirati Arabi Uniti",
  "British, UK": "Regno Unito",
  American: "Stati Uniti d'America",
  Uruguayan: "Uruguay",
  "Uzbekistani, Uzbek": "Uzbekistan",
  "Ni-Vanuatu, Vanuatuan": "Vanuatu",
  Venezuelan: "Venezuela",
  Vietnamese: "Vietnam",
  "British Virgin Island": "Isole Vergini Britanniche",
  "U.S. Virgin Island": "Isole Vergini Americane",
  "Wallis and Futuna, Wallisian or Futunan": "Wallis e Futuna",
  "Sahrawi, Sahrawian, Sahraouian": "Sahara Occidentale",
  Yemeni: "Yemen",
  Zambian: "Zambia",
  Zimbabwean: "Zimbabwe",
};

export const NATIONALITIES = [
  "Afghano",
  "Ålandese",
  "Albanese",
  "Algerino",
  "Samoano Americano",
  "Andorrano",
  "Angolano",
  "Anguillano",
  "Antartico",
  "Antiguano",
  "Argentino",
  "Armeno",
  "Arubano",
  "Australiano",
  "Austriaco",
  "Azerbaigiano",
  "Bahamense",
  "Bahreinita",
  "Bengalese",
  "Barbadiano",
  "Bielorusso",
  "Belga",
  "Beliziano",
  "Beninese",
  "Bermudiano",
  "Bhutanese",
  "Boliviano",
  "Bonairiano",
  "Bosniaco",
  "Botswano",
  "Dell'Isola Bouvet",
  "Brasiliano",
  "Del Territorio Britannico dell'Oceano Indiano",
  "Bruneiano",
  "Bulgaro",
  "Burkinabé",
  "Burundese",
  "Capoverdiano",
  "Cambogiano",
  "Camerunese",
  "Canadese",
  "Caymaniano",
  "Centrafricano",
  "Ciadiano",
  "Cileno",
  "Cinese",
  "Dell'Isola di Natale",
  "Delle Isole Cocos",
  "Colombiano",
  "Comoriano",
  "Congolese",
  "Congolese",
  "Delle Isole Cook",
  "Costaricano",
  "Ivoriano",
  "Croato",
  "Cubano",
  "Di Curaçao",
  "Cipriota",
  "Ceco",
  "Danese",
  "Gibutiano",
  "Dominicano",
  "Dominicano",
  "Ecuadoriano",
  "Egiziano",
  "Salvadoregno",
  "Equatoguineano",
  "Eritreo",
  "Estone",
  "Etiope",
  "Delle Isole Falkland",
  "Faroese",
  "Figiano",
  "Finlandese",
  "Francese",
  "Della Guyana Francese",
  "Della Polinesia Francese",
  "Dei Territori Francesi del Sud",
  "Gabonese",
  "Gambiano",
  "Georgiano",
  "Tedesco",
  "Ghanese",
  "Gibilterrino",
  "Greco",
  "Groenlandese",
  "Grenadino",
  "Guadalupense",
  "Guamano",
  "Guatemalteco",
  "Di Guernsey",
  "Guineano",
  "Guineano-Bissau",
  "Guyanese",
  "Haitiano",
  "Delle Isole Heard e McDonald",
  "Vaticano",
  "Honduregno",
  "Di Hong Kong",
  "Ungherese",
  "Islandese",
  "Indiano",
  "Indonesiano",
  "Iraniano",
  "Iracheno",
  "Irlandese",
  "Mannese",
  "Israeliano",
  "Italiano",
  "Giamaicano",
  "Giapponese",
  "Di Jersey",
  "Giordano",
  "Kazako",
  "Keniota",
  "Kiribatiano",
  "Nordcoreano",
  "Sudcoreano",
  "Kuwaitiano",
  "Kirghiso",
  "Laotiano",
  "Lettone",
  "Libanese",
  "Lesothiano",
  "Liberiano",
  "Libico",
  "Liechtensteinese",
  "Lituano",
  "Lussemburghese",
  "Macanese",
  "Macedone",
  "Malgascio",
  "Malawiano",
  "Malaysiano",
  "Maldiviano",
  "Maliano",
  "Maltese",
  "Marshallese",
  "Martinicano",
  "Mauritano",
  "Mauriziano",
  "Di Mayotte",
  "Messicano",
  "Micronesiano",
  "Moldavo",
  "Monegasco",
  "Mongolo",
  "Montenegrino",
  "Montserratiano",
  "Marocchino",
  "Mozambicano",
  "Birmano",
  "Namibiano",
  "Nauruano",
  "Nepalese",
  "Olandese",
  "Neocaledoniano",
  "Neozelandese",
  "Nicaraguense",
  "Nigerino",
  "Nigeriano",
  "Niueano",
  "Dell'Isola Norfolk",
  "Delle Marianne Settentrionali",
  "Norvegese",
  "Omanita",
  "Pakistano",
  "Palauano",
  "Palestinese",
  "Panamense",
  "Papuano",
  "Paraguaiano",
  "Peruviano",
  "Filippino",
  "Di Pitcairn",
  "Polacco",
  "Portoghese",
  "Portoricano",
  "Qatariota",
  "Riunionese",
  "Rumeno",
  "Russo",
  "Ruandese",
  "Di Saint-Barthélemy",
  "Di Sant'Elena",
  "Di Saint Kitts e Nevis",
  "Di Santa Lucia",
  "Di Saint Martin",
  "Di Saint Pierre e Miquelon",
  "Di Saint Vincent e Grenadine",
  "Samoano",
  "Sammarinese",
  "Sãotomense",
  "Saudita",
  "Senegalese",
  "Serbo",
  "Seychellese",
  "Sierra Leonese",
  "Singaporiano",
  "Di Sint Maarten",
  "Slovacco",
  "Sloveno",
  "Salomonese",
  "Somalo",
  "Sudafricano",
  "Della Georgia del Sud e Sandwich Australi",
  "Sudsudanese",
  "Spagnolo",
  "Srilankese",
  "Sudanese",
  "Surinamese",
  "Delle Svalbard",
  "Swazi",
  "Svedese",
  "Svizzero",
  "Siriano",
  "Taiwanese",
  "Tagiko",
  "Tanzaniano",
  "Thailandese",
  "Timorese",
  "Togolese",
  "Tokelauano",
  "Tongano",
  "Trinidadiano",
  "Tunisino",
  "Turco",
  "Turkmeno",
  "Delle Turks e Caicos",
  "Tuvaluano",
  "Ugandese",
  "Ucraino",
  "Emiratino",
  "Britannico",
  "Statunitense",
  "Uruguaiano",
  "Uzbeko",
  "Vanuatuano",
  "Venezuelano",
  "Vietnamita",
  "Delle Isole Vergini Britanniche",
  "Delle Isole Vergini Americane",
  "Di Wallis e Futuna",
  "Sahrawi",
  "Yemenita",
  "Zambiano",
  "Zimbabwese",
];
