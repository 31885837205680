import React, { useEffect, useRef, useState } from "react";
import { StyledDropdown } from "./StyledDropdown";

const Dropdown = ({
  options,
  label,
  onClick,
  placeholder,
  className,
  defaultValue,
  multiple,
  value,
}: {
  options: string[];
  label?: string;
  value?: string | string[];
  placeholder?: string;
  onClick: (v: string | string[]) => void;
  className?: string;
  defaultValue?: string | string[] | null;
  multiple?: boolean;
}) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [selected, setSelected] = useState<string | string[]>(
    Array.isArray(defaultValue)
      ? defaultValue.filter((v) => v.length)
      : defaultValue ?? ""
  );

  useEffect(() => {
    if (value) {
      setSelected(
        Array.isArray(value) ? value.filter((v) => v.length) : value ?? ""
      );
    } else {
      setSelected(
        Array.isArray(defaultValue)
          ? defaultValue.filter((v) => v.length)
          : defaultValue ?? ""
      );
    }
  }, [value]);

  const handleClick = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      e.target.value === "Seleziona un opzione" ||
      e.target.value === placeholder ||
      e.target.value === "Seleziona"
    ) {
      setSelected(e.target.value);
      onClick("");
    } else {
      setSelected(e.target.value);
      onClick(e.target.value);
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        drawerOpened &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setDrawerOpened(false);
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [drawerOpened]);

  return (
    <StyledDropdown className={className} ref={dropdownRef}>
      {label && <h3>{label}</h3>}
      <div className="select">
        <p>
          {selected.length > 0
            ? Array.isArray(selected)
              ? selected.join(", ").length
                ? selected.join(", ")
                : "Seleziona"
              : selected
            : placeholder ?? "Seleziona"}
        </p>
        {multiple ? (
          <>
            <button
              onClick={() => setDrawerOpened((p) => !p)}
              className="drawer-act"
            ></button>
            <div className={["drawer", drawerOpened ? "open" : ""].join(" ")}>
              <ul>
                {options.map((option) => (
                  <li
                    key={option}
                    onClick={() =>
                      setSelected((p) => {
                        const currentSelected = Array.isArray(p) ? p : [p];
                        const index = currentSelected.indexOf(option);
                        let newSelected = [...currentSelected];
                        if (index !== -1) {
                          newSelected.splice(index, 1);
                        } else if (currentSelected.length < 2) {
                          newSelected.push(option);
                        }
                        onClick(newSelected);
                        return newSelected;
                      })
                    }
                    className={selected.includes(option) ? "selected" : ""}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <select onChange={handleClick} value={selected}>
            <option>Seleziona un opzione</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        <div className="arr-wrapper">
          <span className="arr" />
        </div>
      </div>
    </StyledDropdown>
  );
};

export default Dropdown;
