import React, { useEffect, useState } from "react";
import { StyledDesktopWorkerDetail } from "./StyledDesktopWorkerDetail";
import WorkerCardHeader from "../../../WorkerCard/components/WorkerCardHeader";
import { RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import WorkerDescription from "../../../WorkerCard/components/WorkerDescription";
import chevronDown from "../../../../../../assets/chevron-down.svg";
import closeIcon from "../../../../../../assets/close-x.svg";
import { setDetailedWorker } from "../../../../../../store/slices/vetrina";
import { cosaSoFare } from "../../helpers/propertiesHandlers";
import { Spacer } from "../../../Spacer";
import DropdownListElement from "../DropdownListElement";
import {
  IEsperienzaLavorativa,
  ITestPsicoAttitudinale,
} from "../../../../../../types/vetrina";
import { parseJson } from "../../../../../../utils/jsonUtils";
import { COUNTRIES_MAP } from "../../../../../../constants/countries/countriesNationalities";
import { COUNTRIES_MAP_2 } from "../../../../../../constants/countries/countriesNationalities";

const DesktopWorkerDetail = () => {
  const [compressedSkillsList, setCompressedSkillsList] = useState<{
    badante: boolean;
    colf: boolean;
    baby_sitter: boolean;
    condizioni_accettabili: boolean;
  }>({
    badante: true,
    colf: true,
    baby_sitter: true,
    condizioni_accettabili: true,
  });
  const [skillsList, setSkillsList] = useState<{
    badante: string[];
    colf: string[];
    baby_sitter: string[];
  }>({
    badante: [],
    colf: [],
    baby_sitter: [],
  });
  const [expLav, setExpLav] = useState<IEsperienzaLavorativa[]>([]);
  const [testPA, setTestPA] = useState<ITestPsicoAttitudinale[]>([]);
  const [condizioniAccettabili, setCondizioniAccettabili] = useState<string[]>(
    []
  );
  const dispatchRedux = useDispatch<any>();
  const [esDropOpened, setEsDropOpened] = useState(true);
  const [testDropOpened, setTestDropOpened] = useState(true);
  const detailedWorker = useSelector(
    (state: RootState) => state.vetrina.detailedWorker
  );

  const handleSkillsList = (type: string) => {
    setCompressedSkillsList((p) => ({
      ...p,
      [type]: !p[type as keyof typeof p],
    }));
  };

  const handleClose = () => {
    dispatchRedux(setDetailedWorker(null));
  };

  useEffect(() => {
    setSkillsList((p) => {
      if (!detailedWorker) return p;

      const serializedObject = { ...detailedWorker.hard_skills };

      if (compressedSkillsList.badante) {
        serializedObject.badante = serializedObject.badante?.slice(0, 2) ?? [];
      }
      if (compressedSkillsList.colf) {
        serializedObject.colf = serializedObject.colf?.slice(0, 2) ?? [];
      }
      if (compressedSkillsList.baby_sitter) {
        serializedObject.baby_sitter =
          serializedObject.baby_sitter?.slice(0, 2) ?? [];
      }
      if (compressedSkillsList.condizioni_accettabili) {
        setCondizioniAccettabili((p) => p?.slice(0, 2));
      }
      return serializedObject;
    });
  }, [detailedWorker, compressedSkillsList]);

  useEffect(() => {
    setCondizioniAccettabili(() => {
      const caList = [];
      const serializedObject = detailedWorker?.condizioni_accettabili;

      for (const key in serializedObject) {
        if ((serializedObject as any)[key as any]) {
          caList.push((serializedObject as any)[key as any]);
        }
      }

      return caList.flat(Infinity);
    });
  }, [detailedWorker?.condizioni_accettabili, compressedSkillsList]);

  useEffect(() => {
    setExpLav([]);
    detailedWorker?.experiences?.experiences.forEach((exp) => {
      const experience = parseJson(exp as unknown as string);
      const pExp = parseJson(experience);
      setExpLav((p) => [...p, pExp.values]);
    });
  }, [detailedWorker]);

  if (!detailedWorker) return <></>;

  return (
    <StyledDesktopWorkerDetail className="worker-detail">
      <div className="sidebar-header">
        <img onClick={handleClose} src={closeIcon} alt={""} />
      </div>
      <WorkerCardHeader worker={detailedWorker} />

      <div className="details">
        {detailedWorker.qualifica_professionale &&
        detailedWorker.qualifica_professionale !== "No, non ce l'ho" ? (
          <p className="field">
            🎓 <b>Qualifica:</b> {detailedWorker.qualifica_professionale}
          </p>
        ) : null}
        {detailedWorker.data_di_nascita ? (
          <p className="field">
            🎂 <b>Data di nascita:</b> {detailedWorker.data_di_nascita}
          </p>
        ) : null}
        {/* {detailedWorker?.automunito?.[0] === "Sono automunito" ? (
          <p className="field">
            🚗 <b>Automunito:</b> Ho la macchina
          </p>
        ) : detailedWorker?.patente ? (
          <p className="field">
            🪪 <b>Automunito:</b> Ho la patente ma non l'auto
          </p>
        ) : null} */}
        {(Array.isArray(detailedWorker?.patente) &&
          detailedWorker?.patente[0] === "Ho la patente") ||
        detailedWorker?.patente === "Ho la patente" ? (
          <p className="field">
            🪪 <b>Patente: </b>Ho la patente
          </p>
        ) : (
          <p className="field">
            🪪 <b>Patente: </b> Non ho la patente
          </p>
        )}
        {detailedWorker.paese_origine ? (
          <p className="field">
            🌍 <b>Nazionalità:</b>{" "}
            {COUNTRIES_MAP[
              detailedWorker.paese_origine as keyof typeof COUNTRIES_MAP
            ] ??
              COUNTRIES_MAP_2[
                detailedWorker.paese_origine as keyof typeof COUNTRIES_MAP_2
              ] ??
              detailedWorker.paese_origine}
          </p>
        ) : null}
        {detailedWorker.cittadinanza ? (
          <p className="field">
            🌍 <b>Cittadinanza:</b>{" "}
            {COUNTRIES_MAP[
              detailedWorker.cittadinanza as keyof typeof COUNTRIES_MAP
            ] ??
              COUNTRIES_MAP_2[
                detailedWorker.cittadinanza as keyof typeof COUNTRIES_MAP_2
              ] ??
              detailedWorker.cittadinanza}
          </p>
        ) : null}
        {detailedWorker.conoscenza_italiano ? (
          <p className="field">
            🇮🇹 <b>Il mio livello di italiano è:</b>{" "}
            {detailedWorker.conoscenza_italiano}
          </p>
        ) : null}
        {detailedWorker.lingue_parlate?.length ? (
          <p className="field">
            🗣️ <b>Parlo:</b> {detailedWorker?.lingue_parlate?.join(", ")}
          </p>
        ) : null}
        {/* {workerDetails(detailedWorker).map((detail, i) => {
          return <p key={i}>{detail}</p>;
        })} */}
      </div>
      {detailedWorker?.hard_skills?.badante?.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">I miei punti forti come Badante</h3>
          <ul>
            {detailedWorker.hard_skills.badante.map((skill, i) => {
              return (
                <li key={i}>
                  <p>· {skill}</p>
                </li>
              );
            })}
          </ul>
          {detailedWorker?.hard_skills?.badante?.length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("badante")}
            >
              {compressedSkillsList.badante
                ? "mostra tutte le mie competenze"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {detailedWorker?.hard_skills?.colf?.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">I miei punti forti come Colf</h3>
          <ul>
            {detailedWorker?.hard_skills?.colf?.map((skill, i) => {
              return (
                <li key={i}>
                  <p>· {skill}</p>
                </li>
              );
            })}
          </ul>
          {detailedWorker?.hard_skills?.colf?.length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("colf")}
            >
              {compressedSkillsList.colf
                ? "mostra tutte le mie competenze"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {detailedWorker?.hard_skills?.baby_sitter?.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">
            I miei punti forti come Baby Sitter
          </h3>
          <ul>
            {detailedWorker?.hard_skills?.baby_sitter?.map((skill, i) => {
              return (
                <li key={i}>
                  <p>· {skill}</p>
                </li>
              );
            })}
          </ul>
          {detailedWorker?.hard_skills?.baby_sitter?.length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("baby_sitter")}
            >
              {compressedSkillsList.baby_sitter
                ? "mostra tutte le mie competenze"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {condizioniAccettabili.length > 0 ? (
        <div className="skills">
          <h3 className="secondary-title">Cosa posso fare?</h3>
          <ul>
            {condizioniAccettabili.map((ca, i) => {
              return (
                <li key={i}>
                  <p>· {ca}</p>
                </li>
              );
            })}
          </ul>
          {Object.values(detailedWorker?.condizioni_accettabili).flat(Infinity)
            .length > 2 ? (
            <button
              className="btn-more-skills"
              onClick={() => handleSkillsList("condizioni_accettabili")}
            >
              {compressedSkillsList.condizioni_accettabili
                ? "mostra tutto quello che posso fare"
                : "comprimi"}
            </button>
          ) : null}
        </div>
      ) : null}
      {expLav.length > 0 ? (
        <div>
          <h3
            className="secondary-title"
            onClick={() => setEsDropOpened((p) => !p)}
          >
            Le mie esperienze <img src={chevronDown} alt="" />
          </h3>
          <ul
            className={`dropdown dropdown-esperienze ${
              esDropOpened ? "opened" : ""
            }`}
          >
            {expLav.map((exp, i) => {
              return (
                <div>
                  <h3>Esperienza come {exp?.esperienza_come}</h3>
                  <p>Inizio: {exp?.inizio_esperienza}</p>
                  <p>Fine: {exp?.fine_esperienza}</p>
                  <p>
                    {(() => {
                      if (typeof exp?.modalita_lavoro_esperienza === "string") {
                        return exp?.modalita_lavoro_esperienza;
                      } else if (
                        Array.isArray(exp?.modalita_lavoro_esperienza)
                      ) {
                        return exp?.modalita_lavoro_esperienza?.join(", ");
                      } else {
                        return "";
                      }
                    })()}
                  </p>
                  <p>{exp?.mansioni?.join(", ")}</p>
                </div>
              );
            })}
          </ul>
        </div>
      ) : null}
      {testPA.length > 0 ? (
        <div>
          <h3
            className="secondary-title"
            onClick={() => setTestDropOpened((p) => !p)}
          >
            Test psico-attitudinale <img src={chevronDown} alt="" />
          </h3>
          <ul
            className={`dropdown dropdown-test ${
              testDropOpened ? "opened" : ""
            }`}
          >
            {testPA.map((ta, i) => {
              const title = `${ta.question}`;

              return (
                <DropdownListElement
                  isLast={i === testPA.length - 1}
                  title={title}
                  description={ta.answer}
                  key={i}
                />
              );
            })}
          </ul>
        </div>
      ) : null}
    </StyledDesktopWorkerDetail>
  );
};

export default DesktopWorkerDetail;
