import PropTypes from "prop-types";
import React from "react";
import "./style.css";

const Button = ({
  property1 = "primary",
  className = "",
  text = undefined,
  onClick,
  children,
  disabled = false,
  loading = false,
  id = "",
  leftDecorator = undefined,
  rightDecorator = undefined,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      id={id.length > 0 ? id : undefined}
      className={`button ${property1} ${className}`}
      disabled={disabled || loading}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: disabled || loading ? 0.5 : 1,
        filter: disabled || loading ? "grayscale(100%)" : "none",
      }}
      onClick={handleClick}
    >
      {leftDecorator && leftDecorator}
      <span className="button-primary">
        {property1 === "primary" && (
          <>{typeof text == "undefined" ? children : text}</>
        )}
        {property1 === "secondary" && (
          <>{typeof text == "undefined" ? children : text}</>
        )}
      </span>
      {rightDecorator && rightDecorator}
    </button>
  );
};

Button.propTypes = {
  property1: PropTypes.oneOf(["primary", "secondary"]),
  text: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default Button;
